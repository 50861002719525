import type { PsCart } from "@vue-storefront/prestashop-api";

const useAnalytics = () => {
  const { data } = useBootstrap();
  const gtm = useGtm();

  const addOrRemoveFromCart = (product: any, event: string) => {
    if (!gtm || !process.client) {
      return;
    }

    if (Array.isArray(product)) {
      const items = product as PsCart['products'];
      gtm.trackEvent({
        event: event,
        ecommerce: {
          value: items.reduce((acc, item) => acc + item.price_wt * item.quantity, 0),
          items: items.map((item) => ({
            google_business_vertical: 'retail',
            item_id: item.id_product + (item?.id_product_attribute ? '-' + item?.id_product_attribute : ''),
            item_name: item.name,
            item_brand: item.manufacturer_name,
            item_category: item.category,
            price: item.price_wt,
            quantity: item.quantity,
          })),
        }
      })
    } else {
      gtm.trackEvent({
        event,
        ecommerce: {
          currency: data.value?.currencies?.current_currency?.iso_code,
          value: product.price_amount * product.qty,
          items: [
            {
              google_business_vertical: 'retail',
              item_id: product.id_product + (product?.id_product_attribute ? '-' + product?.id_product_attribute : ''),
              item_name: product.name,
              index: 0,
              item_brand: product.brand,
              item_category: product.category_name,
              price: product.price_amount,
              quantity: product.qty,
            },
          ],
        },
      });
    }

  };

  const makePurchase = (order: any) => {
    if (!gtm || !process.client) {
      return;
    }

    const items = Object.values(order.products).map((item: any, index: number) => ({
      item_id: item.id_product + (item?.id_product_attribute ? '-' + item?.id_product_attribute : ''),
      item_name: item.name,
      index: index,
      price: item.product_price_wt,
      quantity: item.quantity,
      google_business_vertical: 'retail',
    }));

    gtm.trackEvent({
      event: 'purchase',
      ecommerce: {
        transactionId: order.details.id,
        value: order.totals.total.amount,
        tax: order.subtotals.tax.amount,
        shipping: order.subtotals.shipping.amount,
        currency: data.value?.currencies?.current_currency?.iso_code,
        items,
      },
    });
  };

  return {
    addOrRemoveFromCart,
    makePurchase,
  };
};

export default useAnalytics;
